import React, { useCallback, useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import '../../styles/competition.less'
import videoConditions from './img/conditions-image.png'
import ScrollToTopOnMount from '../../helpers/scrollRestorationFix'
import firstLastYear from './img/firstIconLastYear.svg'
import secondLastYear from './img/secondIconLastYear.svg'
import thirdLastYear from './img/thirdIconLastYear.svg'
import fourthLastYear from './img/fourthIconLastYear.svg'
import fifthLastYear from './img/fifthIconLastYear.svg'
import airURL from './img/book.png'
import iphoneURL from './img/phone.png'
import ipadURL from './img/tablet.png'
import watchURL from './img/clock.png'
import yaBoxURL from './img/alice.png'
import first from './img/first.svg'
import second from './img/second.svg'
import third from './img/third.svg'
import fourth from './img/fourth.svg'
import fifth from './img/fifth.svg'
import miniStation from './img/stationMini.png'
import appleTV from './img/appleTV.png'
import { useScreenSize } from '../../hooks'
import analyticsContext, {
  AnalyticsProvider,
  useAnalytics,
} from '../../context/ga'
import { ModalProvider } from '../../context/modal'
import PopupRegistration from '../../components/PopupRegistration'
import superPrizeImgLarge from './img/money-1024.png'
import superPrizeImgTablet from './img/money-768.png'
import bankiru from './img/partners/bankiru.svg'
import bo from './img/partners/bo.svg'
import peopleIpoteka from './img/partners/peopleIpoteka.svg'
import TTFinance from './img/partners/TTFinance.svg'
import nedviza from './img/partners/nedviza.png'
import mgk from './img/partners/mgk.png'
import grm from './img/partners/grm.svg'
import nbj from './img/partners/nbj.svg'
import cottage from './img/partners/cottage.svg'
import urbanus from './img/partners/urbanus.svg'
import gaInit from '../../helpers/gaInit'
import { Video } from '../../components/ContestVideo'
import concursAgreement from '../../static/docs/Положение о публичном конкурсе.pdf'
import videoBg from './img/video-bg.jpg'
import { AreaProvider } from '../../context/area'
import { getCurrentYear } from '../../components/Footer/helpers'

const DESCRIPTION = 'Закрой больше всех сделок\n' + 'и получи 1 000 000 рублей'

const prizes = [
  {
    id: 1,
    title: 'MacBook Air 13, 256 ГБ',
    image: airURL,
    icon: first,
  },
  {
    id: 2,
    title: 'iPhone 11, 64 ГБ',
    image: iphoneURL,
    icon: second,
  },
  {
    id: 3,
    title: 'iPad Pro 2020 11", 128 ГБ',
    image: ipadURL,
    icon: third,
    slim: true,
  },
  {
    id: 4,
    title: 'Apple Watch Series 6, 44 мм',
    image: watchURL,
    icon: fourth,
    slim: true,
  },
  {
    id: 5,
    title: 'Яндекс.Станция + ELARI SmartLED Color',
    image: yaBoxURL,
    icon: fifth,
    slim: true,
  },
]
const miniPrizes = [
  {
    id: 6,
    description: `Яндекс.Станция Мини`,
    title: 'самая быстрая сделка месяца',
    image: miniStation,
    text: `Доведите сделку до выдачи кредита за самое короткое время. Вручаем каждый месяц`,
  },
  {
    id: 7,
    description: `Apple TV 4K, 64 ГБ`,
    title: `успешный старт`,
    image: appleTV,
    text: (
      <>
        Пройдите курс в{' '}
        <a
          href="https://university.ipoteka.center"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ипотека.Университет
        </a>{' '}
        и&nbsp;заключите больше всего сделок
      </>
    ),
  },
]
const lastYear = [
  {
    id: 8,
    icon: firstLastYear,
    text: `Мы учредили ежегодный конкурс для пользователей нашей платформы в 2019 году`,
    width: '52px',
    height: '41px',
  },
  {
    id: 9,
    icon: secondLastYear,
    text: `Приняли участие больше 800 участников со всей России`,
    width: '48px',
    height: '40px',
  },
  {
    id: 10,
    icon: thirdLastYear,
    text: `5 лучших получили ценные призы и признание профессионального сообщества`,
    width: '41px',
    height: '40px',
  },
  {
    id: 11,
    icon: fourthLastYear,
    text: `РБК, Интерфакс и другие деловые СМИ опубликовали имена победителей`,
    width: '39px',
    height: '40px',
  },
  {
    id: 12,
    icon: fifthLastYear,
    text: `Торжественная церемония прошла в Mercury Space`,
    width: '35px',
    height: '66px',
    top: '-70px',
  },
]
const partnerTypes = {
  general: 'Генеральный информационный партнёр',
  specialInfo: 'Специальный информационный партнёр',
  info: 'Информационный партнёр',
  specialStrategic: 'Специальный стратегический партнёр',
  official: 'Официальный партнёр',
}
const banks = [
  {
    img: bankiru,
    href: 'https://www.banki.ru/',
    alt: 'Партнер Банки.ру',
    partnerType: partnerTypes.general,
    className: 'banki',
  },
  {
    img: bo,
    href: 'https://bosfera.ru/',
    alt: 'Партнер Банковское образование',
    partnerType: partnerTypes.specialInfo,
    className: 'bo',
  },
  {
    img: TTFinance,
    alt: 'Партнер ТТ Финанс',
    href: 'https://www.ttfinance.ru/',
    partnerType: partnerTypes.info,
    className: 'TTFinance',
  },
  {
    img: nedviza,
    alt: 'Партнер Новости недвижимости',
    href: 'https://nedvizha.ru/',
    partnerType: partnerTypes.info,
    className: 'nedviza',
  },
  {
    img: mgk,
    alt: 'Партнер Международный жилищный конгресс',
    href: 'https://spbcongress.ru',
    partnerType: partnerTypes.specialStrategic,
    className: 'mgk',
  },
  {
    img: grm,
    href: 'https://grmos.ru',
    alt: 'Партнер Гильдия риэлторов Москвы',
    partnerType: partnerTypes.official,
    className: 'grm',
  },
  {
    img: nbj,
    href: 'https://nbj.ru/',
    alt: 'Партнер Национальный банковский журнал',
    partnerType: partnerTypes.specialInfo,
    className: 'nbj',
  },
  {
    img: cottage,
    href: 'https://www.cottage.ru',
    alt: 'Партнер Cottage.ru - самый известный, посещаемый и авторитетный сайт по загородной недвижимости в Рунете',
    partnerType: partnerTypes.info,
    className: 'cottage',
  },
  {
    img: urbanus,
    href: 'https://www.urbanus.ru',
    alt: 'Партнер Urbanus.ru - интернет-портал о городской жилой недвижимости',
    partnerType: partnerTypes.info,
    className: 'urbanus',
  },
]
const CompetitionHeader = ({ handleRegisterOpen }) => {
  const { ClientId } = useContext(analyticsContext)

  const openContestRegistrationAnalytics = useAnalytics(() => ({
    eventLabel: 'konkurs',
    clickText: 'Зарегистрироваться',
    event: 'click1',
    eventCategory: 'click',
    eventAction: 'btn1',
  }))

  const handleClick = useCallback(() => {
    handleRegisterOpen()
    openContestRegistrationAnalytics(ClientId)
  }, [handleRegisterOpen, ClientId])

  return (
    <section className="competition__header">
      <div className="competition__header-logo">
        <a href="/" target="_blank"></a>
      </div>
      <h1 className="competition__header-title">
        Закрой больше всех сделок и получи 1 000 000 ₽
      </h1>
      <div className="competition__header-subtitle">
        Награждение и&nbsp;объявления победителей конкурса &laquo;Лучший
        ипотечный эксперт России&raquo; состоится в&nbsp;рамках закрытой
        церемонии.
      </div>
      <button className="competition__button competition__button__disabled">
        Конкурс завершен
      </button>
    </section>
  )
}
const CompetitionFooter = ({ handleRegisterOpen }) => {
  const { ClientId } = useContext(analyticsContext)

  const openContestRegistrationAnalytics = useAnalytics({
    eventLabel: 'konkurs',
    clickText: 'Зарегистрироваться',
    event: 'click2',
    eventCategory: 'click',
    eventAction: 'btn2',
  })

  const handleClick = useCallback(() => {
    handleRegisterOpen()
    openContestRegistrationAnalytics()
  }, [handleRegisterOpen, ClientId])
  //
  return (
    <div className="competition__footer-wrapper">
      <section className="competitionFooter">
        <h2 className="competitionFooterTitle">
          Готовы побороться за миллион?
        </h2>
        <p className="competitionFooterText">
          Регистрируйтесь и&nbsp;вступайте в&nbsp;борьбу за&nbsp;ценные призы.
        </p>
        <button className="competition-footer__button competition__button__disabled">
          Конкурс завершен
        </button>
        <div className="competitionFooterContacts">
          <p className="footerCopyright">
            © 2018−{getCurrentYear()} Ипотека.Центр
          </p>
          <ul className="socialLinks">
            <li>
              <a
                href="https://vk.com/ipotekacenter"
                className="socialLink socialLink--vk"
                rel="noreferrer noopener"
                target="_blank"
              />
            </li>
          </ul>
        </div>
      </section>
    </div>
  )
}

const Contest = () => {
  const isMobile = useScreenSize('mobile')
  const isSmallTablet = useScreenSize('smallTablet')
  const isTablet = useScreenSize('tablet')
  const isLargeDesktop = useScreenSize('largeDesktop')
  const [isRegisterOpen, setIsRegisterOpen] = useState(false)
  const handleRegisterOpen = useCallback(() => {
    setIsRegisterOpen(true)
  }, [])
  const handleRegisterClose = useCallback(() => setIsRegisterOpen(false), [])
  return (
    <ModalProvider>
      <AreaProvider>
        <AnalyticsProvider>
          <Helmet>
            <title>Закрой больше всех сделок и получи 1 000 000 рублей</title>
            <meta name="description" content={DESCRIPTION} />
            <meta property="og:description" content={DESCRIPTION} />
            <script>{gaInit()}</script>
          </Helmet>
          <ScrollToTopOnMount />
          <PopupRegistration
            onClose={handleRegisterClose}
            visible={{ isOpen: isRegisterOpen }}
            gaTag={'headerScrn'}
            registrationSource={'Contest'}
          />
          <div style={{ overflow: 'hidden' }}>
            <div className="competition__header-wrapper">
              <CompetitionHeader handleRegisterOpen={handleRegisterOpen} />
            </div>
            <section className="conditions">
              <h2 className="conditions__title">Условия участия</h2>
              <div className="conditions__content">
                <div className="conditions__text">
                  <p>
                    В&nbsp;этом году конкурс &laquo;Лучший ипотечный эксперт
                    России 2020&raquo; будет проходить с&nbsp;октября
                    по&nbsp;декабрь. Участвовать может любой зарегистрированный
                    пользователь платформы.
                  </p>
                  <p>
                    Претенденты на&nbsp;победу должны закрыть наибольшее
                    количество сделок с&nbsp;1&nbsp;октября
                    по&nbsp;31&nbsp;декабря. При равном количестве сделок
                    приоритет отдается тому, у&nbsp;кого больше итоговая сумма
                    выдач.
                  </p>
                  <p>
                    Все участники должны быть готовы к&nbsp;публичности
                    и&nbsp;славе :)
                  </p>
                  <a
                    href={concursAgreement}
                    className="linkCondition"
                    rel="noreferrer noopener"
                    target="_blank"
                    download="Положение о публичном конкурсе"
                  >
                    {isMobile
                      ? 'Скачать подробные условия'
                      : 'Скачать подробные условия конкурса'}
                  </a>
                </div>
                <div className="conditions__video">
                  <Video
                    data={{
                      attributes: {
                        src: 'https://youtu.be/cAYqTFjKnZk',
                        image: videoConditions,
                      },
                    }}
                    options={{
                      gridTemplateColumns: '1fr',
                      borderRadius: '10px',
                      overflow: 'hidden',
                      height: '100%',
                      width: '100%',
                      description: false,
                      stat: false,
                      videoWidth: '100%',
                      videoHeight: '100%',
                      playBtnWidth: '80px',
                      playBtnHeight: '80px',
                      titleInline: true,
                    }}
                  />
                </div>
              </div>
            </section>
            <section className="winners">
              <h2 className="winnersTitle competition__titles">
                Победители получат
              </h2>
              <div className="superPrize">
                <span className="superPrizeSpan">суперприз</span>
                <h2 className="superPrizeTittle">
                  1&nbsp;000&nbsp;000&nbsp;<span className="roubleSign">₽</span>
                </h2>
                <p className="superPrizeText">
                  Заключите больше 40&nbsp;сделок и&nbsp;поборитесь
                  за&nbsp;суперприз
                </p>
                <img
                  src={
                    isTablet || isMobile || isSmallTablet
                      ? superPrizeImgTablet
                      : superPrizeImgLarge
                  }
                  className="superPrizeImg"
                />
              </div>
              <div className="winnerList">
                {prizes.map((prize, i) => (
                  <div
                    key={prize.id}
                    className={`winnersItem winnersItem-${i + 1}  ${
                      prize.slim ? 'winnersItem-slim' : ''
                    }`}
                  >
                    <div className="winnersItemWrapper">
                      {prize.image && (
                        <div
                          className={`winnerImage ${
                            prize.slim && 'winnerImage-slim'
                          } winnerImage-image${prize.id}`}
                        />
                      )}
                      {prize.description && (
                        <div className="winnerDescription">
                          {prize.description}
                        </div>
                      )}
                    </div>
                    {prize.title && (
                      <div className="winnerTitle">{prize.title}</div>
                    )}
                  </div>
                ))}
              </div>
            </section>
            <section className="miniPrizes">
              {miniPrizes.map((prize) => (
                <div
                  key={prize.id}
                  className={`prizeItem prizeItem-image${prize.id}`}
                >
                  <p className="prizeTitle">{prize.title}</p>
                  <h2 className="prizeDescription">{prize.description}</h2>
                  <p className="prizeText">{prize.text}</p>
                </div>
              ))}
            </section>
            <section className="lastYear">
              <p className="lastYear-text">
                {isMobile
                  ? 'Победители получат'
                  : isLargeDesktop
                  ? 'Как это было год назад'
                  : 'Как это было?'}
              </p>
              <div className="lastYearContainer">
                {lastYear.map((card) => (
                  <div
                    key={card.id}
                    className={`historyLastYear historyLastYear-card${card.id}`}
                  >
                    <span>{card.text}</span>
                  </div>
                ))}
              </div>
            </section>
            <section className="eventsImages">
              <div className="eventsImages-video">
                <Video
                  data={{
                    attributes: {
                      title: 'Видеоотчёт с церемонии 2019',
                      duration: '01:40',
                      src: 'https://youtu.be/aGKomTBylEM',
                      image: videoBg,
                    },
                  }}
                  options={{
                    gridTemplateColumns: '1fr',
                    height: '100%',
                    width: '100%',
                    description: false,
                    stat: false,
                    videoWidth: '100%',
                    videoHeight: '100%',
                    playBtnWidth: '190px',
                    playBtnHeight: '190px',
                    titleInline: true,
                  }}
                />
              </div>
            </section>
            <section className="partners">
              <h2 className="partners__title">Наши партнёры</h2>
              {banks.map((partner) => (
                <a
                  href={partner.href}
                  rel="noreferrer noopener"
                  target="_blank"
                  className={`partners__card partners__card--${partner.className}`}
                >
                  <img src={partner.img} alt={partner.alt} />
                  <p className="partners__card-text">{partner.partnerType}</p>
                </a>
              ))}
            </section>
            <div className="competition__footer-wrapper">
              <CompetitionFooter handleRegisterOpen={handleRegisterOpen} />
            </div>
          </div>
        </AnalyticsProvider>
      </AreaProvider>
    </ModalProvider>
  )
}
export default Contest
